.bg {
  position: absolute;
  z-index: -3;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #181818;
}

.logo {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
}

.text {
  padding: 0px 350px;
  position: relative;
  top: 60px;
  left: 0px;
  color: white;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

.footer {
  position: relative;
  top: 700px;
  left: 0px;
  color: white;
  display: flex;
  justify-content: center;
}
